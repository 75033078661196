/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  font-family:"Roboto";
  z-index: 9999999;
  position: absolute;
  &.hide-modal {
    position: absolute;
    bottom: 0;
  }
  .modal {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: rgba(6, 9, 16, 0.92);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
    &.visible {
      pointer-events: auto;
      opacity: 1;
      z-index: 100000;
      transition: all 300ms ease-in-out;
      height: 100vh;
      .modal_wrap {
        max-width: 90%;
        opacity: 1;
        transform: scale(1);
        transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
      }
    }
    .popup {
      .close_btn {
        height: fit-content;
        margin-right: -16px;
      }
    }
    .modal-wrapper {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      max-width: 90%;
      .roi-content {
        background-color: var(--white);
        border-radius: 16px;
        overflow: hidden;
        height: 470px;
        width: 570px;
        @media (max-width: 991px) {
          max-width: 370px;
          height: auto;
        }
        @media (max-width: 768px) {
          max-width: 298px;
        }
        .cta-wrapper {
          text-align: center;
          padding: 0 32px 32px;
          @media (max-width: 991px) {
            padding: 0 24px 24px;
          }
          .kicker {
            color: #1c3fff;
            margin-top: 16px;
            font-weight: 900;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }
          .heading {
            margin-bottom: 12px;
          }
          .sub-head {
            font-size: 16.2px;
            color: #2c3641;
            margin-bottom: 32px;
          }
          .cta-roi {
            padding: 12px 18px;
            background: #1c3fff !important;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            border: none;
            text-decoration: none;
            font-weight: 900;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            color: #f7f9fc;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .modal_wrap {
    position: relative;
    display: block;
    min-height: 400px;
    width: 1170px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    align-self: center;
    opacity: 0;
    max-width: 90%;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
    @media (max-width: 992px) {
      width: 100%;
      min-width: auto;
      padding: 10px;
    }
    .close_btn {
      cursor: pointer;
      width: 16px;
      height: 16px;
      position: absolute;
      right: -30px;
      top: -30px;
      z-index:100;
      @media (max-width: 992px) {
        right: 15px;
      }
    }
    .video-container {
      img {
          width: 100%
      }
      .videoframe {
        margin-bottom: 12px;
        width: 100%;
        min-height: 600px;
        @media (max-width: 768px) {
          min-height: 400px;
        }
      }
    }
    .video-content {
      .content {
        max-width: 600px;
        @media (max-width: 992px) {
          max-width: 80%;
          margin-bottom: 12px;
        }
        @media (max-width: 576px) {
          max-width: 100%;
          margin-top: 56px;
        }
      }
      .cta-link {
        button {
          margin-top: 32px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
    }
    .modal_kicker {
      font-weight: 900;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #AEA0EC;
      margin-bottom: 12px;
      @media (max-width: 576px) {
        text-align: center;
      }
    }
    .modal_heading {
      font-style: normal;
      font-weight: bold;
      text-align: left;
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 12px;
      @media (max-width: 992px) {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 12px;
      }
      @media (max-width: 576px) {
        text-align: center;
        margin-top: 12px;
      }
      color: #ffffff;
    }
    .modal_subheading {
      font-style: normal;
      font-weight: normal;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      color: #E4E7EC;
      @media (max-width: 576px) {
        text-align: center;
      }
    }
    button {
      margin-right: 0;
    }
  }

  .modal.demo {
    @media (max-width: 768px) {
      overflow: auto;
    }
  }

  .demo-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .close_btn {
      right: 90px;
      @media (max-width: 991px) {
        right: 15px;
      }
    }

    @media (max-width: 768px) {
      min-width: auto;
      width: 100%;
    }
  }
`;
