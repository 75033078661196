import styled from "styled-components";

export const SwiperWrapper = styled.div`
  .slick-arrow {
    ::before {
      display: none;
    }
  }
  .slick-next {
    z-index: 1;
    top: 630px;
    right: 0;
    @media (max-width: 768px) {
      top: 430px;
    }
    @media (max-width: 576px) {
      right: calc(50% - 30px);
    }
  }
  .slick-prev {
    z-index: 1;
    top: 630px;
    left: calc(100% - 65px);
    @media (max-width: 768px) {
      top: 430px;
    }
    @media (max-width: 576px) {
      left: calc(50% - 40px);
    }
  }
  .videoframe {
    iframe {
      width: 100%;
      height: 600px;
      @media (max-width: 768px) {
        height: 400px;
      }
    }
    img {
      width: 100%;
      height: 600px;
      @media (max-width: 768px) {
        height: 400px;
      }
    }
  }
`