import React, { useRef, useState } from "react";
import SwiperVideoContainer from "./SwiperVideoContainer";
import Slider from "react-slick";
import { ModalCaptionContent } from "./ModalCaptionContent";
import { NextArrow, PreviousArrow } from "../TileSection/components/Buttons";
import { SwiperWrapper } from "./styles/SwiperModal.styled";

export const SwiperModal = ({ show, swiperLinks, tileIndex, thumbnail }) => {

  const [slideInactive, setSlideInactive] = useState(false)
  const sliderRef = useRef()

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    initialSlide: tileIndex,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    beforeChange: ()=> setSlideInactive(true),
  };

  return (
    <SwiperWrapper>
      {show && 
        <Slider {...settings} ref={sliderRef}>
          {swiperLinks?.map((link, index) => (
            <div className={index}>
              <SwiperVideoContainer
                youtubeUrl={link?.link}
                key={"videoSlide-"+index}
                slide={index}
                slideInactive={slideInactive}
                setSlideInactive={setSlideInactive}
                thumbnail={link?.thumbnail}
              />
              <ModalCaptionContent reference={link?.reference}/>
            </div>
          ))}
        </Slider>
      }
    </SwiperWrapper>
  )
}