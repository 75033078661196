import React, { useEffect, useState } from 'react'
import { getYoutubeId } from '../../utils/getYoutubeId'

const VideoContainer = ({ youtubeUrl, heading }) => {
  const [videoSource, setVideoSource] = useState('')
  useEffect(() => {
    if (youtubeUrl) {
      setVideoSource(
        `https://www.youtube.com/embed/${getYoutubeId(youtubeUrl)}?autoplay=0`
      );
    }
  }, [youtubeUrl])

  return (
    <iframe
      className="embed-responsive-item videoframe"
      src={videoSource}
      title={heading}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      controls="0"
      scrolling="no"
      loading="lazy"
      aria-hidden={!heading}
    />
  )
}

export default VideoContainer;
