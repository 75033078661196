import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import ModalContainer from '../../styles/components/VideoModal'
import closeBtn from '../../assets/icons/close.svg'
import ModalContent from './ModalContent'
import { ModalCaptionContent } from "./ModalCaptionContent"
import ReactDOM from "react-dom"
import { createWrapperAndAppendToBody } from '../../utils/createWrapperAndAppendToBody'
import FocusTrap from 'focus-trap-react'
import onKeyDown from '../../utils/onKeyDown'

const Modal = ({ show, videoId, reference, onClose, swiperLinks, tileIndex, wrapperId = "react-portal-wrapper" }) => {
  const [domReady, setDomReady] = useState(false)
  const [wrapperElement, setWrapperElement] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      modalRef?.current?.classList?.add('visible');
    } else {
      modalRef?.current?.classList?.remove('visible');
    }
  }, [show]);

  useEffect(()=> {
    setDomReady(true)
  }, [])

  // This useLayoutEffect was informed by https://blog.logrocket.com/build-modal-with-react-portals/ 
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    }
  }, [wrapperId]);

  return domReady && ReactDOM.createPortal(
    <ModalContainer id={wrapperId}>
      <div ref={modalRef} className="modal">
        <div className="modal_wrap">
          <img
            src={closeBtn}
            onClick={onClose}
            className="close_btn"
            alt={reference?.title || 'close icon'}
            role="button"
            onKeyDown={(e) => onKeyDown(e, onClose)}
            aria-label="close modal"
            tabIndex={0}
          />
          <div className="video-container">
            <ModalContent tileIndex={tileIndex} reference={reference} swiperLinks={swiperLinks} show={show} videoId={videoId} />
          </div>
          {!swiperLinks &&
            <ModalCaptionContent reference={reference}/>
          }
        </div>
      </div>
    </ModalContainer>,
    wrapperElement
  );
};

export default React.memo(Modal);
