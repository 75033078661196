import React, { useEffect, useState } from 'react'
import { getYoutubeId } from '../../utils/getYoutubeId'
import YouTube from 'react-youtube';
import OptimizedImage from '../../utils/optimizedImage';

const VideoContainer = ({ youtubeUrl, heading, slide, slideInactive, setSlideInactive, thumbnail }) => {

  const [youtubePlayer, setYoutubePlayer] = useState({
    player:{}
  })

  let event 
  const handleReady = (e) => {
    event = e.target
    setYoutubePlayer({player: event})
  }

  useEffect(()=> {
    if (youtubePlayer?.player?.H) {
      if (slideInactive) {
        youtubePlayer?.player?.pauseVideo()
        setSlideInactive(false)
      }
    }
  }, [youtubePlayer, slideInactive, setSlideInactive])

  if (youtubeUrl) {
    return (
      <YouTube
        className={`embed-responsive-item videoframe`}
        videoId={`${getYoutubeId(youtubeUrl)}`}
        title={heading}
        id={`player-${slide}`}
        onReady={handleReady}
      />
    )
  }
  if (!youtubeUrl) {
    return (
      <OptimizedImage image={thumbnail?.gatsbyImageData} src={thumbnail?.file?.url} alt="" id={`player-${slide}`} className="videoframe"/>
    )
  }
}

export default VideoContainer;
