import React from "react"
import { ContentfulImage } from '@components/ContentfulImage';
import VideoContainer from "./VideoContainer";
import { SwiperModal } from "./SwiperModal";

const ModalContent = ({ reference, videoId, show, swiperLinks, tileIndex }) => {
  if (reference?.internal?.type === "ContentfulComponentImage") {
    return (
      <ContentfulImage imageData={reference}/>
    )
  }
  if (swiperLinks) {
    return (
      <SwiperModal swiperLinks={swiperLinks} tileIndex={tileIndex} show={show} />
    )
  }
  return (
    <VideoContainer
      youtubeUrl={videoId}
      heading={reference?.title}
      show={show}
    />
  )
}

export default ModalContent
